export const appHead = {"link":[{"rel":"shortcut icon","href":"/favicon-black.svg?v=2","media":"(prefers-color-scheme: light)"},{"rel":"shortcut icon","href":"/favicon-white.svg?v=2","media":"(prefers-color-scheme: dark)"},{"rel":"icon","href":"/favicon-black.svg","type":"image/svg","sizes":"16x16"},{"rel":"icon","href":"/favicon-white.svg","type":"image/svg","sizes":"16x16"}],"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":""},{"name":"format-detection","content":"telephone=no"},{"property":"og:type","content":"website"}],"style":[],"script":[{"src":"/termly_blocking_map.js","data-termly-config":true},{"src":"https://app.termly.io/resource-blocker/6b530648-095a-4fc8-934a-69ec057f6ab0?autoBlock=on"}],"noscript":[],"htmlAttrs":{"lang":"en"}}

export const appPageTransition = {"name":"page","mode":"out-in"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = true

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false