const linkResolver = (doc) => {
  if (doc.isBroken) {
    return "/not-found";
  }

  if (doc.type === "home") {
    return "/";
  }

  if (doc.type === "pages") {
    return "/" + doc.uid;
  }

  if (doc.type === "articles") {
    return "/articles/" + doc.uid;
  }

  if (doc.type === "events") {
    return "/whats-on/" + doc.uid;
  }

  if (doc.type === "memberships") {
    return "/memberships/" + doc.uid;
  }

  if (doc.type === "donations") {
    return "/donations";
  }

  return "/not-found";
};

export default linkResolver;
