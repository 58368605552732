import type { RouterOptions } from "@nuxt/schema";

export default {
  scrollBehavior(to, from, savedPosition) {
    return {
      left: 0,
      top: 0,
    };
  },
} as RouterOptions;
