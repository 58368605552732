<template>
  <NuxtLayout>
    <div class="container flex items-center pb-20 pt-60 md:pt-80">
      <!-- 404 -->
      <div class="content-start">
        <div class="grid grid-cols-3 gap-4">
          <div class="col-span-2">
            <h1 class="my-10 text-hero-header md:text-desktop-hero-header">
              {{ error?.statusCode }}
            </h1>
            <h2 class="mb-10 text-large-copy md:text-desktop-large-copy">
              {{
                (error?.statusCode == 404)
                  ? "We can't seem to find the page you're looking for."
                  : "Sorry, something seems to have gone wrong, please try again later."
              }}
            </h2>
            <UIButton
              type="dark"
              label="Return to homepage"
              class="mt-4"
              @click="handleError"
            />
          </div>
        </div>
      </div>
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
const error = useError();

const handleError = () => {
  clearError({ redirect: "/" });
};

onUnmounted(() => {
  clearError();
});
</script>
