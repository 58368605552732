<template>
  <NuxtLink
    :to="props.link || ''"
    :target="props.target"
    class="cursor-pointer text-center font-semibold"
    :class="[
      props.type === 'primary' && 'border border-blue-75 bg-blue-75 text-white transition-colors hover:border-blue-100 hover:bg-blue-100',
      props.type === 'dark' && 'border border-charcoal-100 bg-charcoal-100 text-white transition-colors hover:border-charcoal-75 hover:bg-charcoal-75',
      props.type === 'light' && 'border border-charcoal-100 bg-white text-charcoal-100 transition-colors hover:bg-charcoal-25',
      props.type === 'text' && '!p-0 text-charcoal-100 underline-offset-4 after:relative after:left-[10px] after:inline-block after:w-[14px] after:transition-all after:content-[url(~/assets/svgs/button_arrow.svg)] hover:underline hover:after:left-[20px]',
      props.size === 'large' ? 'rounded-md px-5 py-2 text-large-copy' : 'rounded-md px-3.5 py-1.5 text-body-copy',
      props.block ? 'block' : 'mr-1 inline-block first:mr-0',
    ]
    "
  >
    {{ props.label }}
  </NuxtLink>
</template>

<script lang="ts" setup>
const props = defineProps<{
  type: "primary" | "dark" | "light" | "text";
  label: string | null;
  link?: string | null;
  size?: "large" | "small" | "";
  target?: "blank" | "self";
  block?: boolean;
}>();
</script>
