<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup>
const nuxtApp = useNuxtApp();

useHead({
  titleTemplate: (title) => {
    return title ? `${title} | Lightbox Gallery` : "Lightbox Gallery";
  },
});

nuxtApp.hook("page:finish", () => {
  window.scrollTo(0, 0);
});
</script>
