export default {
  heading1: ({ children }) => `<h1 class="text-header1 md:text-desktop-header1 my-10">${children}</h1>`,
  heading2: ({ children }) => `<h2 class="text-header2 md:text-desktop-header2 my-10">${children}</h2>`,
  heading3: ({ children }) => `<h3 class="text-header3 md:text-desktop-header3 my-4">${children}</h3>`,
  heading4: ({ children }) => `<h4 class="text-header4 md:text-desktop-header4 my-4">${children}</h4>`,
  heading5: ({ children }) => `<h5 class="text-large-copy md:text-desktop-large-copy">${children}</h5>`,

  paragraph: ({ children }) => `<p class="mb-4">${children}</p>`,
  // preformatted: ({ node }) => `<pre>${JSON.stringify(node.text)}</pre>`,
  // strong: ({ children }) => `<strong>${children}</strong>`,
  // em: ({ children }) => `<em>${children}</em>`,
  // listItem: ({ children }) => `<li>${children}</li>`,
  // oListItem: ({ children }) => `<li>${children}</li>`,
  // list: ({ children }) => `<ul>${children}</ul>`,
  // oList: ({ children }) => `<ol>${children}</ol>`,

  // hyperlink: ({ node, children }) => {
  //   const target = node.data.target
  //     ? `target="${node.data.target}" rel="noopener"`
  //     : "";
  //   const url = linkResolver(node.data)
  //   return `<a ${target} href="${url}">${children}</a>`
  // },

  image: ({ node }) => {
    const linkUrl = node.linkTo ? node.linkTo.url : null;
    const linkTarget
      = node.linkTo && node.linkTo.target
        ? `target="${node.linkTo.target}" rel="noopener"`
        : "";
    const img = `<img src="${node.url}" defer alt="${node.alt ? node.alt : ""
    }" copyright="${node.copyright ? node.copyright : ""}" />`;

    return `
        <figure class="block" style="margin-top:80px" defer center">
          ${linkUrl ? `<a href="${linkUrl}" ${linkTarget} >${img}</a>` : img}
          ${node.alt ? `<figcaption style="margin:8px 0">${node.alt}</figcaption>` : ""}
        </figure>
      `;
  },

  // embed: ({ node }) => `
  //   <div data-oembed="${node.oembed.embed_url}"
  //     data-oembed-type="${node.oembed.type}"
  //     data-oembed-provider="${node.oembed.provider_name}"
  //     ${label(node)}>
  //     ${node.oembed.html}
  //   </div>
  // `,

  // label: ({ node, children }) => {
  //   return `<span class="${node.data.label}">${children}</span>`
  // },

  // span: ({ text }) => (text ? text : ''),
};
