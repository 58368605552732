import { default as _91uid_93JInuVsK1iaMeta } from "/opt/build/repo/pages/[uid].vue?macro=true";
import { default as _91uid_93ueUXU2kVKbMeta } from "/opt/build/repo/pages/articles/[uid].vue?macro=true";
import { default as indexQ2Wa6y1KPhMeta } from "/opt/build/repo/pages/articles/index.vue?macro=true";
import { default as donationsscvwskMSG2Meta } from "/opt/build/repo/pages/donations.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as _91uid_93xfpQUrvCkAMeta } from "/opt/build/repo/pages/memberships/[uid].vue?macro=true";
import { default as previewAhKeF96wPcMeta } from "/opt/build/repo/pages/preview.vue?macro=true";
import { default as _91query_93OHYP4xQFOIMeta } from "/opt/build/repo/pages/search/[query].vue?macro=true";
import { default as slice_45simulatoraGnv8AQLQlMeta } from "/opt/build/repo/pages/slice-simulator.vue?macro=true";
import { default as typographypCEYTJy3CgMeta } from "/opt/build/repo/pages/typography.vue?macro=true";
import { default as _91uid_93r1y4EkZO4HMeta } from "/opt/build/repo/pages/whats-on/[uid].vue?macro=true";
import { default as indexiQGifqSfzJMeta } from "/opt/build/repo/pages/whats-on/index.vue?macro=true";
export default [
  {
    name: _91uid_93JInuVsK1iaMeta?.name ?? "uid",
    path: _91uid_93JInuVsK1iaMeta?.path ?? "/:uid()",
    meta: _91uid_93JInuVsK1iaMeta || {},
    alias: _91uid_93JInuVsK1iaMeta?.alias || [],
    redirect: _91uid_93JInuVsK1iaMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[uid].vue").then(m => m.default || m)
  },
  {
    name: _91uid_93ueUXU2kVKbMeta?.name ?? "articles-uid",
    path: _91uid_93ueUXU2kVKbMeta?.path ?? "/articles/:uid()",
    meta: _91uid_93ueUXU2kVKbMeta || {},
    alias: _91uid_93ueUXU2kVKbMeta?.alias || [],
    redirect: _91uid_93ueUXU2kVKbMeta?.redirect,
    component: () => import("/opt/build/repo/pages/articles/[uid].vue").then(m => m.default || m)
  },
  {
    name: indexQ2Wa6y1KPhMeta?.name ?? "articles",
    path: indexQ2Wa6y1KPhMeta?.path ?? "/articles",
    meta: indexQ2Wa6y1KPhMeta || {},
    alias: indexQ2Wa6y1KPhMeta?.alias || [],
    redirect: indexQ2Wa6y1KPhMeta?.redirect,
    component: () => import("/opt/build/repo/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: donationsscvwskMSG2Meta?.name ?? "donations",
    path: donationsscvwskMSG2Meta?.path ?? "/donations",
    meta: donationsscvwskMSG2Meta || {},
    alias: donationsscvwskMSG2Meta?.alias || [],
    redirect: donationsscvwskMSG2Meta?.redirect,
    component: () => import("/opt/build/repo/pages/donations.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91uid_93xfpQUrvCkAMeta?.name ?? "memberships-uid",
    path: _91uid_93xfpQUrvCkAMeta?.path ?? "/memberships/:uid()",
    meta: _91uid_93xfpQUrvCkAMeta || {},
    alias: _91uid_93xfpQUrvCkAMeta?.alias || [],
    redirect: _91uid_93xfpQUrvCkAMeta?.redirect,
    component: () => import("/opt/build/repo/pages/memberships/[uid].vue").then(m => m.default || m)
  },
  {
    name: previewAhKeF96wPcMeta?.name ?? "preview",
    path: previewAhKeF96wPcMeta?.path ?? "/preview",
    meta: previewAhKeF96wPcMeta || {},
    alias: previewAhKeF96wPcMeta?.alias || [],
    redirect: previewAhKeF96wPcMeta?.redirect,
    component: () => import("/opt/build/repo/pages/preview.vue").then(m => m.default || m)
  },
  {
    name: _91query_93OHYP4xQFOIMeta?.name ?? "search-query",
    path: _91query_93OHYP4xQFOIMeta?.path ?? "/search/:query()",
    meta: _91query_93OHYP4xQFOIMeta || {},
    alias: _91query_93OHYP4xQFOIMeta?.alias || [],
    redirect: _91query_93OHYP4xQFOIMeta?.redirect,
    component: () => import("/opt/build/repo/pages/search/[query].vue").then(m => m.default || m)
  },
  {
    name: slice_45simulatoraGnv8AQLQlMeta?.name ?? "slice-simulator",
    path: slice_45simulatoraGnv8AQLQlMeta?.path ?? "/slice-simulator",
    meta: slice_45simulatoraGnv8AQLQlMeta || {},
    alias: slice_45simulatoraGnv8AQLQlMeta?.alias || [],
    redirect: slice_45simulatoraGnv8AQLQlMeta?.redirect,
    component: () => import("/opt/build/repo/pages/slice-simulator.vue").then(m => m.default || m)
  },
  {
    name: typographypCEYTJy3CgMeta?.name ?? "typography",
    path: typographypCEYTJy3CgMeta?.path ?? "/typography",
    meta: typographypCEYTJy3CgMeta || {},
    alias: typographypCEYTJy3CgMeta?.alias || [],
    redirect: typographypCEYTJy3CgMeta?.redirect,
    component: () => import("/opt/build/repo/pages/typography.vue").then(m => m.default || m)
  },
  {
    name: _91uid_93r1y4EkZO4HMeta?.name ?? "whats-on-uid",
    path: _91uid_93r1y4EkZO4HMeta?.path ?? "/whats-on/:uid()",
    meta: _91uid_93r1y4EkZO4HMeta || {},
    alias: _91uid_93r1y4EkZO4HMeta?.alias || [],
    redirect: _91uid_93r1y4EkZO4HMeta?.redirect,
    component: () => import("/opt/build/repo/pages/whats-on/[uid].vue").then(m => m.default || m)
  },
  {
    name: indexiQGifqSfzJMeta?.name ?? "whats-on",
    path: indexiQGifqSfzJMeta?.path ?? "/whats-on",
    meta: indexiQGifqSfzJMeta || {},
    alias: indexiQGifqSfzJMeta?.alias || [],
    redirect: indexiQGifqSfzJMeta?.redirect,
    component: () => import("/opt/build/repo/pages/whats-on/index.vue").then(m => m.default || m)
  }
]